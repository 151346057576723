.register_container {
  width: 40%;
  margin: auto;
  margin-top: 70px;
  padding-top: 60px;
  font-family: "Poppins", sans-serif;
}

.error {
  color: red;
  font-weight: 400;
  margin-top: 10px;
}

.register_form {
  margin-top: 20px;
  display: grid;
  row-gap: 30px;
  column-gap: 20px;
  grid-template-areas:
    "header header1"
    "main main"
    "main1 main1"
    "main2 main2"
    "main3 main3"
    "main4 main4"
    "main5 main5"
    "footer footer"
    "foote1 foote1";
}

.register_form > div:nth-child(1) {
  grid-area: header;
}

.register_form > div:nth-child(2) {
  grid-area: header1;
}

.register_form > div:nth-child(3) {
  grid-area: main;
}

.register_form > div:nth-child(4) {
  grid-area: main1;
}

.register_form > div:nth-child(5) {
  grid-area: main2;
}

.register_form > div:nth-child(6) {
  grid-area: main3;
}

.register_form > div:nth-child(7) {
  grid-area: main4;
}

.register_form > div:nth-child(8) {
  grid-area: main5;
}

.register_form > div:nth-child(9) {
  grid-area: footer;
}

.register_form > div {
  width: 100%;
}

.register_form .text_text,
.forgetPassword_text {
  width: 100% !important;
  margin: 0;
  text-align: start;
  font-weight: 600;
  font-size: 16px;
}

.forgetPassword_text {
  text-align: end !important;
  margin-top: 15px !important;
  display: block;
  color: #26ddf9;
  font-weight: 400 !important;
}

.form_input {
  width: 100%;
  border: none;
  outline: navajowhite;
  border: solid 1px rgb(204, 205, 205);
  height: 50px;
  border-radius: 10px;
  background-color: white;
  color: rgb(1, 3, 4);
  padding: 0 20px;
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
}

.register_form .terms {
  line-height: 30px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.terms_container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.terms_container svg {
  width: 10%;
  cursor: pointer;
}

.terms_container .terms {
  width: 90%;
}

.register_container .btn {
  width: 100%;
  background-color: var(--other-color);
  font-size: 17px;
  color: white;
  height: 55px;
  line-height: 25px;
}

.register_form a {
  color: #26ddf9;
}

.login_container .register_form {
  grid-template-areas: none;
}

.login_container .register_form > div {
  grid-area: auto;
}

.login_container .terms_container .terms {
  width: 100%;
}

.register_container .text_2 {
  letter-spacing: 2px;
}

.forget_password .text_2 {
  margin-bottom: 30px;
}

.register_password {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.register_password svg {
  cursor: pointer;
}
.register_password input {
  border: none;
  outline: none;
  height: 100%;
  width: 90%;
}

.login_form {
  gap: 0px;
}

.login_form > div {
  margin-bottom: 15px;
}

@media (max-width: 600px) {
  .register_container {
    width: 100%;
    padding: 50px 20px;
  }

  .register_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .login_container .register_form {
    gap: 0px;
  }
}
