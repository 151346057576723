.exchange_hero_sect {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
  padding: 50px;
  align-items: center;
  gap: 80px;
}

.exchange_hero_sect_text_sect {
  width: 45%;
}

.exchange_hero_sect_text_sect .btn {
  width: 200px;
  font-size: 17px;
  margin-top: 30px;
  background-color: var(--other-color);
  color: white;
  height: 60px;
}

.exchange_hero_sect_image_sect {
  width: 55%;
}

.exchange_hero_sect_text_sect .header_text {
  text-align: start;
  margin: 0;
  font-size: 72px;
  letter-spacing: 3px;
  line-height: 80px;
}

.reversed_exchange_hero_sect {
  flex-direction: row-reverse;
}

.reversed_exchange_hero_sect > div {
  width: 50%;
}

.exchange_hero_sect img {
  height: 400px;
}

.exchange_hero_sect .home_header_text {
  font-size: 36px;
}

.exchange_award_sect {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px 40px;
  padding: 50px;
  background-color: white;
}

.exchange_award_sect > div {
  display: flex;
  gap: 15px;
  align-items: center;
}

.exchange_award_sect img {
  width: 70px;
  height: auto;
}

.exchange_award_sect h5,
.exchange_award_sect .text_text {
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  text-align: start;
  margin: 0;
}

.exchange_award_sect p {
  margin-bottom: 7px;
}

.exchange_last_sect {
  padding: 50px 40px;
  padding-bottom: 80px;
}

.exchange_last_sect_flex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  margin-top: 40px;
}

.exchange_last_sect_flex > div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.exchange_last_sect_flex .text_text {
  width: 100%;
  text-align: start;
  margin: 0;
  font-weight: 400;
}

.exchange_last_sect_flex img {
  height: 40px;
  width: 40px;
}

@media (max-width: 600px) {
  .exchange_last_sect_flex {
    grid-template-columns: 1fr;
  }

  .exchange_hero_sect {
    flex-direction: column;
    padding: 20px;
  }

  .exchange_hero_sect_text_sect,
  .exchange_hero_sect_image_sect,
  .reversed_exchange_hero_sect > div {
    width: 100%;
  }

  .exchange_hero_sect_image_sect img {
    height: auto;
  }

  .exchange_award_sect,
  .exchange_last_sect {
    grid-template-columns: 1fr;
    padding: 50px 20px;
  }

  .exchange_hero_sect_text_sect .btn {
    height: 48px;
    width: 160px;
    font-size: 16px;
    font-weight: 500;
  }
}
