.about_hero_sect {
    background: linear-gradient(269deg, rgb(0, 0, 0) 33%, rgb(49, 49, 49) 100%, rgba(0, 0, 0, 0) 98.3%);
    margin-top: 70px;
    padding: 130px 150px;
    color: white;
    display: flex;
    flex-direction: column;
}

.secondary_color {
    color: var(--secondary-color);
    margin-left: 8px;
}

.about_hero_sect .text_2 {
    text-align: start;
    padding-right: 100px;
    font-size: 35px;
    line-height: 40px;
}

.about_hero_sect .header_text {
    font-size: 80px;
    color: white;
    line-height: 73px;
    text-align: start;
    width: 90%;
    margin-top: 50px;
}

.about_hero_sect .header_text .secondary_color {
    margin-left: 12px;
}

.about_first_sect {
    padding: 70px 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.about_first_sect .text_text {
    margin: 0;
    width: 100%;
    font-weight: 400;
}

.about_second_sect {
    padding: 50px 110px;
    background-color: white;
}

.about_sect_two_item_one {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about_sect_two_item_one .text_text,
.about_last_sect .text_text,
.pillars_company_sect .text_text {
    width: 100%;
    font-weight: 400;
}

.about_pillars_sect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    margin-top: 60px;
    padding: 0 150px;
    border-bottom: 2px solid rgb(230, 230, 230);
    padding-bottom: 50px;
}

.about_pillars_sect_items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about_pillars_sect img {
    height: 60px;
    width: 60px;
}

.about_pillars_sect_text_container {
    display: flex;
    justify-content: space-between;
    gap: 70px;
    margin-top: 30px;
    border-bottom: 2px solid rgb(230, 230, 230);
    padding-bottom: 50px;
}


.about_pillars_sect_text_container .text_text,
.exchange_hero_sect_text_sect .text_text {
    text-align: start;
    width: 100%;
    margin-top: 20px;
}

.exchange_hero_sect_text_sect .text_text {
    font-weight: 400;
}

.pillars_company_sect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    margin-top: 50px;
}

.pillars_company_sect .text_text {
    text-align: start;
}

.pillars_company_sect img {
    height: 100px;
    width: 100px;
}

.column_about_first_sect {
    flex-direction: column;
    gap: 20px;
}

.column_about_first_sect .text_text:nth-of-type(2) {
    text-decoration: underline;
}

.about_last_sect {
    background-color: rgb(26, 28, 29);
    padding: 80px 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    text-align: center;
}

.about_last_sect .home_header_text {
    font-size: 33px;
}

.about_last_sect .btn {
    width: 180px;
    font-size: 16px;
    margin-top: 20px;
    height: 55px;
}

@media(max-width:600px) {
    .about_last_sect {
        padding: 50px 20px;
    }

    .about_first_sect,
    .about_second_sect {
        padding: 50px 20px;
    }

    .about_last_sect .home_header_text {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
    }

    .about_hero_sect {
        padding: 100px 20px;
    }

    .about_hero_sect .header_text {
        font-size: 50px;
        line-height: 45px;
        font-weight: 600;
        margin-top: 20px;
        width: 100%;
        padding: 0;
    }

    .about_hero_sect .text_2 {
        font-size: 25px;
        line-height: 30px;
        padding-right: 0;
        font-weight: 600;
    }

    .about_pillars_sect,
    .about_pillars_sect_text_container,
    .pillars_company_sect {
        flex-direction: column;
    }

    .about_pillars_sect {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: flex-start;
        padding: 30px 20px;
        gap: 50px;
    }

    .about_pillars_sect_text_container {
        gap: 20px;
    }

    .pillars_company_sect {
        margin-top: 30px;
        gap: 15px;
    }

    .pillars_company_sect .text_text {
        margin-top: 20px;
        text-align: center;
    }


}