.transaction_wrapper {
  border: 1px solid rgb(50, 56, 63);
  color: white;
  padding: 20px;
  border-radius: 10px;
}

.transaction_wrapper_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.transaction_wrapper h3 {
  font-size: 16px;
  font-weight: 400;
  color: rgb(141, 169, 197);
}
.transaction_wrapper p {
  color: rgb(165, 177, 189);
  font-size: 14px;
}

.transaction_sn,
.transaction_type {
  width: 7.5%;
}

.transaction_time,
.transaction_method {
  width: 20%;
}

.transaction_amount,
.transaction_status {
  width: 10%;
}

.transaction_ref {
  width: 25%;
}

.transaction_ref_text {
  margin-left: 15px;
  text-align: center;
}

.transaction_status_text,
.profit_pending_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 12px;
  height: 35px;
}

.transaction_status_text_true,
.profit_pending_wrapper {
  background-color: rgba(0, 179, 89, 0.15);
  border: 1px solid #00b359;
  border-radius: 10px;
}

.transaction_status_text_false {
  border: 1px solid rgb(211, 9, 9);
  border-radius: 10px;
}
.transaction_status_text_processing {
  border: 1px solid rgb(165, 177, 189);
  border-radius: 10px;
}

.transaction_status_text_true_text,
.profit_pending_wrapper_text {
  color: #00b359 !important;
}

.transaction_status_text_false_text {
  color: rgb(226, 34, 34) !important;
}

.profit_pending_wrapper {
  width: 200px;
  margin-top: 10px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .transaction_wrapper {
    width: 1200px;
  }
}
