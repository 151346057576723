.wallet_total_balance_sect {
  display: none;
}

@media (max-width: 600px) {
  .wallet_total_balance_sect {
    display: block;
    padding: 20px;
    font-family: "Poppins", sans-serif;
  }

  .wallet_total_balance_sect h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .wallet_total_balance_sect > div {
    margin-bottom: 30px;
  }
}
