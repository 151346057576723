.settings_wrapper {
  font-family: "Poppins", sans-serif;
}
.settings_tab {
  padding: 20px;
  background-color: rgb(36, 39, 58);
  outline: rgb(40, 45, 60) solid 1px;
  border-radius: 12px;
}

.settings_container {
  padding: 30px;
  margin-top: 100px;
}

.setting_btn_wrapper button {
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  border: none;
  color: rgb(165, 177, 189);
  font-size: 15px;
  cursor: pointer;
}

.settings_btn_wrapper .active_button {
  height: 40px;
  text-align: center;
  border-radius: 8px;
  background-color: rgb(36, 39, 58);
  color: rgb(165, 177, 189);
  border: none;
  padding: 0 10px;
  font-weight: 500;
  font-size: 16px;
}

.settings_btn_wrapper {
  display: flex;
  gap: 30px;
  background-color: rgb(28, 33, 39);
  padding: 15px;
  border-radius: 10px;
}

.settings_tab_2 {
  margin-top: 30px;
}

.submitted_btn {
  border: 1px solid #00b359;
  border-radius: 10px;
  color: #00b359;
  height: 35px;
  background-color: rgba(0, 179, 89, 0.15);
  padding: 0px 20px;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.profile_circle {
  color: rgb(165, 177, 189);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgb(28, 33, 39);
}

.settings_tab_2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settings_tab_2 > div {
  display: flex;
  gap: 15px;
  color: rgb(165, 177, 189);
}

.settings_tab_2 > div h3,
.personal_info_sect > h3 {
  font-weight: 600;
  font-size: 18px;
}

.profile_circle h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 500;
}

.personal_info_sect {
  margin-top: 30px;
}

.personal_info_sect {
  color: rgb(165, 177, 189);
}

.details_sect {
  margin-top: 20px;

  padding: 40px 20px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 35px;
}

.details_sect .details_label {
  text-transform: uppercase;
  color: white;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 500;
}

.personal_info_sect .submitted_btn {
  cursor: pointer;
}

.first_withdrawal_modal_inner label {
  display: block;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .settings_container {
    padding: 30px 20px;
    margin-top: 30px;
  }
  .settings_tab {
    overflow-x: scroll;
  }

  .setting_btn_wrapper {
    width: 450px;
    justify-content: center;
  }
  .settings_tab_2 {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .details_sect {
    grid-template-columns: 1fr;
    padding-bottom: 100px;
  }
}
