.first_withdrawal_modal_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50px;
  overflow-y: scroll;
}

.first_withdrawal_modal_inner {
  width: 35%;
  margin: auto;
  background-image: url(../../../../../assets/need-help-bg.071e9de701e0d1900fdd.png);
  padding: 30px;
  border-radius: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40px;
}

.first_withdrawal_modal_inner p {
  font-size: 15px;
  line-height: 26px;
}

.first_withdrawal_modal_inner button,
#transact_btn {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: rgb(50, 56, 63);
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  font-family: "Poppins", san-serif;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  height: 55px;
}

.first_withdrawal_modal_inner button:nth-child(2) {
  border: 1px solid rgb(50, 56, 63);
  background-color: transparent;
  color: rgb(50, 56, 63);
}

.first_withdrawal_header_text {
  text-align: center;
}

.first_withdrawal_header_text span {
  font-size: 16px;
  font-weight: 600;
}

.first_withdrawal_modal_inner_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.first_withdrawal_modal_inner .fJUhOY {
  margin-bottom: 10px;
  height: 55px;
  margin-top: 20px;
  font-size: 16px;
  border-radius: 10px;
}

@media (max-width: 600px) {
  .first_withdrawal_modal_inner {
    width: 100%;
    padding: 30px 20px;
    color: rgb(50, 56, 63);
  }

  .first_withdrawal_modal_wrapper {
    padding: 50px 20px;
  }
}
