.partners_sect_one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 70px;
  margin-top: 70px;
  padding: 40px;
  padding-top: 100px;
}

.partners_sect_text_sect {
  width: 50%;
}

.partners_sect_two {
  margin-top: 0px;
}

.partners_sect_text_sect .header_text {
  font-size: 72px;
  line-height: 88px;
  font-weight: 800;
  text-align: start;
  margin-bottom: 15px;
}

.partners_sect_text_sect .text_text {
  width: 100%;
  text-align: start;
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: rgb(1, 3, 4);
  margin-bottom: 10px;
}

.partners_sect_one_img_sect {
  width: 50%;
}

.partners_page > .text_2 {
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  font-weight: 500;
  margin: 70px 0;
}

.partners_sect_two .partners_sect_one_img_sect {
  display: flex;
  align-items: center;
  justify-content: center;
}

.partners_sect_two .partners_sect_text_sect img {
  width: 80%;
  height: auto;
  margin-bottom: 30px;
}

.partners_sect_two .partners_sect_one_img_sect img {
  height: 500px;
  width: auto;
}

.partners_sect_two a {
  text-decoration: underline;
}

.partners_sect_two .text_text {
  font-size: 18px;
}

.partners_sect_two_reverse {
  flex-direction: row-reverse;
}

.partners_sect_two_reverse .partners_sect_one_img_sect img {
  width: 600px;
}

.trading_view_image img {
  width: 600px !important;
}

.brave_image {
  width: 200px !important;
}

.partners_page .exchange_last_sect_flex {
  padding: 40px 80px;
}

.partners_page .exchange_last_sect_flex img {
  height: 60px;
  width: 60px;
}

@media (max-width: 600px) {
  .partners_sect_one {
    flex-direction: column;
    padding: 50px;
    padding: 20px;
  }

  .partners_sect_text_sect,
  .partners_sect_one_img_sect {
    width: 100%;
  }

  .partners_sect_text_sect .header_text,
  .exchange_hero_sect_text_sect .header_text {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 0;
  }

  .exchange_hero_sect_text_sect .header_text {
    margin-top: 15px;
    line-height: 40px;
  }

  .partners_sect_two_reverse .partners_sect_one_img_sect img,
  .partners_sect_two .partners_sect_one_img_sect img {
    width: 100% !important;
    height: auto;
  }

  .partners_page > .text_2 {
    font-size: 24px;
    font-weight: 500;
    width: 80%;
    margin: 30px auto;
    line-height: 32px;
  }

  .partners_page .exchange_last_sect_flex {
    padding: 40px 20px;
  }
}
