footer {
  background-color: white;
  padding: 50px 40px;
  padding-top: 80px;
}

.footer_item_one {
  display: flex;
  gap: 50px;
}

.footer_newslet_sect img {
  margin-bottom: 40px;
  width: 120px;
}

.footer_newslet_sect .btn {
  width: 160px;
  height: 55px;
  font-size: 16px;
  background-color: var(--other-color);
  color: white;
}

footer input {
  height: 50px;
  width: 100%;
  border: none;
  outline: none;
  border: 1px solid var(--other-color);
  margin: 10px 0;
  border-radius: 10px;
  padding-left: 20px;
}

.footer_item_one > div {
  width: 25%;
}

.footer_partners_sect h3,
.footer_support_sect h3,
.footer_about_sect h3 {
  margin-bottom: 50px !important;
}

.footer_partners_sect p,
.footer_support_sect p,
.footer_about_sect p {
  margin-bottom: 20px;
}

.footer_item_two {
  display: flex;
  align-items: center;
  gap: 30px;
  cursor: pointer;
  margin-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.footer_icon_container {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.1);
}

.footer_item_two svg {
  font-size: 20px;
}

.footer_item_three {
  display: flex;
  align-items: center;
  margin-top: 30px;
  gap: 20px;
  justify-content: space-between;
}

.footer_item_three p {
  font-size: 12px;
  text-align: center;
}

.footer_item_three a {
  text-decoration: underline;
  margin: 0 2px;
}

@media (max-width: 600px) {
  footer {
    padding: 20px;
  }

  .footer_item_one,
  .footer_item_three {
    flex-direction: column;
  }

  .footer_item_one > div {
    width: 100%;
  }

  .footer_newslet_sect img {
    margin-bottom: 20px;
    height: 70px;
    width: 70px;
  }
}
