@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

:root {
  --secondary-color: #26ddf9;
  --other-color: #010304;
  --white-color: white;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
}

a,
li {
  list-style: none;
  text-decoration: none;
}

.tradingview-widget-copyright {
  display: none;
}

img {
  width: 100%;
  height: auto;
}

input,
select {
  font-size: 16px;
}

.loader_wrapper {
  background-color: var(--other-color);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #26ddf9;
  gap: 15px;
  font-size: 25px;
  font-weight: 400;
}
