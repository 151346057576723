.prices_sect {
  background-color: white;
}

.prices_container {
  margin-top: 70px;
}

.prices_container > .header_text {
  color: var(--other-color);
  padding: 30px;
  text-align: start;
}

.prices_table {
  padding: 30px;
}

.prices_table_header {
  margin-bottom: 40px;
}

.prices_table_body {
  margin: 20px 0;
}

.prices_table_header,
.prices_table_body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.price_container .prices_table_body,
.price_container .prices_table_header {
  padding-bottom: 10px;
  border-bottom: none;
}

.prices_table .text_text {
  text-align: start;
  width: 100%;
  font-size: 16px;
  margin: 0;
}

.price_container .text_text,
.wallet_total_balance_sect h3 {
  color: rgb(167, 177, 188);
}

.prices_table_header > div,
.prices_table_body > div {
  width: 14%;
}

.prices_name {
  width: 30% !important;
  display: flex;
  gap: 15px;
}

.prices_name img {
  height: 40px;
  width: 40px;
}

.prices_name h3 {
  font-size: 20px;
  margin-bottom: 5px;
}

.price_container .prices_name h3 {
  color: rgb(167, 177, 188);
}

.prices_table_body .prices_name p,
.prices_container > p,
.wallet_total_balance_sect .indi {
  color: rgb(103, 104, 104);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
}

.prices_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.prices_btn .btn {
  background-color: var(--other-color);
  color: white;
  font-size: 15px;
  width: 100px;
  height: 50px;
}

.prices_table_body .prices_hour .text_text,
.prices_table_body .prices_percent .text_text {
  color: rgb(14, 133, 53);
  font-size: 16px;
  line-height: 24px;
}

.loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
  margin-bottom: 50px;
}

.loader_container > p {
  color: var(--other-color);
}

.prices_container > p {
  margin: 30px;
  text-transform: capitalize;
}

@media (max-width: 600px) {
  .prices_table {
    padding: 0 20px;
  }

  .prices_table_header,
  .prices_table_body > div {
    display: none;
  }

  .prices_table_body > div {
    width: 50% !important;
  }

  .prices_btn,
  .prices_name {
    display: flex !important;
  }

  .prices_container > .header_text {
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    padding: 20px;
  }

  .prices_name_item h3 {
    font-weight: 500;
    font-size: 16px;
  }

  .price_container .prices_price {
    display: flex !important;
  }

  .price_container .prices_price .text_text {
    text-align: end;
  }

  .price_container .prices_table_body {
    align-items: start;
  }
}
