@media (max-width: 600px) {
  .bottom_nav {
    background-color: rgba(38, 40, 49);
    width: 100%;
    position: fixed;
    bottom: 0px;
    z-index: 9999;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .bottom_nav .radius_sect {
    margin: 0px;
    border-radius: 0px;
    padding: 15px 20px;
    width: 100%;
  }

  .bottom_nav .radius_sect p,
  .radius_sect svg {
    color: rgb(167, 177, 189);
  }
}
