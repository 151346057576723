.home_sect,
.about_sect,
.exchange_page,
.privacy_container_item_one {
  background-color: rgb(224, 219, 219);
}

.home_hero_sect {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: flex-end;
  padding-top: 100px;
  margin-top: 70px;
}

.home_hero_sect_one {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  margin-right: -50px;
  position: absolute;
  inset: 0;
  margin: auto;
  margin-top: 60px;
}

.home_hero_sect_one .btn,
.home_direct_btn_container .btn {
  width: 200px;
  height: 50px;
  font-size: 18px;
  margin-top: 20px;
  font-weight: 500;
}

.header_text {
  font-size: 62px;
  line-height: 60px;
  letter-spacing: -2.64px;
  font-weight: 700;
  color: var(--other-color);
  text-align: center;
}

.text_text {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  margin-top: 30px;
  text-align: center;
  z-index: 1;
  width: 90%;
}

.home_hero_tablet_sect {
  width: 35%;
  height: 400px;
}

.home_hero_trading_sect {
  width: 33%;
  margin-top: 100px;
}

.bold_home_text {
  margin-top: 80px;
  color: rgb(77, 79, 79);
}

.home_sect > .text_2 {
  margin: auto;
  color: rgb(77, 79, 79);
  width: 60%;
  text-align: center;
  margin-top: 20px;
}

.text_2 {
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -1px;
  font-weight: 500;
}

.home_referral_sect {
  width: 90%;
  margin: 40px auto;
  background-image: url(../../assets/Referral_Club_Desktop.avif);
  height: 550px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
}

.align_left {
  color: white;
  text-align: start;
}

.arrow_btn {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  text-align: center;
  background-color: rgba(38, 221, 249, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_header_text,
.home_sect_four .align_left,
.home_sect_five .align_left {
  font-size: 45px;
  line-height: 48px;
}

.home_header_text {
  font-size: 48px;
  font-weight: 600;
}

.referral_item_one {
  width: 35%;
  color: white;
}

.referral_item_two {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.referral_item_two .home_header_text {
  font-size: 36px;
}

.unlock_home_sect {
  background-image: url(../../assets/ActiveTraderBackground__1_.avif) !important;
}

.unlock_home_sect .arrow_btn {
  background-color: rgba(255, 255, 255, 0.3);
}

.unlock_home_sect .referral_item_one {
  width: 50%;
  margin-top: 100px;
}

.home_sect_three {
  height: 550px;
  display: flex;
  border-radius: 10px;
  width: 90%;
  margin: auto;
}

.home_sect_three_item_one {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.home_sect_three .align_left {
  color: var(--other-color);
}

.home_sect_three_item_one .referral_item_one {
  width: 60%;
  background-color: red;
}

.home_sect_three_item_three {
  background-image: url(../../assets/trading2.avif);
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 45%;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.home_sect_three .home_header_text {
  color: var(--other-color);
}

.home_sect_four {
  display: flex;
  width: 90%;
  margin: 40px auto;
  gap: 20px;
}

.home_sect_four .referral_item_two {
  margin-top: 15px;
}

.home_sect_four .referral_item_two .text_2 {
  color: white;
}

.home_sect_four p {
  font-size: 10px;
  line-height: 15px;
  margin-top: 10px;
}

.home_sect_four_item_one {
  background: linear-gradient(
    184.5deg,
    rgb(38, 221, 249) 2.88%,
    rgb(0, 176, 203) 76.95%
  );
}

.home_sect_four img {
  width: 180px;
  margin: auto;
}

.home_sect_four_item_two {
  background: linear-gradient(rgb(164, 133, 255) 0%, rgb(104, 51, 255) 100%);
}

.home_sect_four_item_two .arrow_btn {
  background: linear-gradient(rgb(104, 51, 255) 100%, rgb(164, 133, 255) 0%);
}

.home_sect_four_item_three {
  background-image: url(../../assets/nft.avif);
  background-position: center center;
  background-color: rgba(0, 0, 0, 0.7);
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: color-burn;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.home_sect_four_item_three .arrow_btn {
  background-color: rgba(0, 0, 0, 0.3);
}

.home_sect_four_item_three .home_header_text {
  margin-bottom: 20px;
}

.home_sect_four > div {
  width: 33.3%;
  padding: 40px 30px;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
}

.home_sect_five {
  background-image: url(../../assets/homepage-perpsBg.avif);
}

.home_sect_five .referral_item_one {
  width: 45%;
}

.home_sect_five .arrow_btn {
  background-color: rgba(255, 255, 255, 0.3);
}

.home_sect_six {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 90%;
  margin: 40px auto;
  gap: 15px;
  grid-template-areas:
    "header bigMain bigMain"
    "main bigMain bigMain"
    "footer footer footer1";
}

.home_sect_six > div {
  padding: 40px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}

.home_sect_six .text_2:nth-child(1),
.home_sect_sev .text_2,
.footer_item_one h3 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  margin-bottom: 10px;
}

.home_sect_six .text_2:nth-child(2) {
  font-size: 24px;
  line-height: 1.16;
  padding-right: 60px;
  font-weight: 600;
}

.home_sect_six .home_header_text {
  font-size: 40px;
  padding-right: 20px;
}

.home_sect_six .referral_item_two {
  margin-top: 30px;
}

.home_sect_six .arrow_btn,
.home_sect_sev .arrow_btn {
  height: 30px;
  width: 30px;
}

.home_sect_six_item_1 {
  grid-area: header;
  background-image: url(../../assets/earthBg.avif);
  background-color: rgba(0, 0, 0, 0.9);
  background-blend-mode: color-burn;
  color: white;
}

.home_sect_six_item_1 .arrow_btn {
  background-color: rgba(255, 255, 255, 0.3);
}

.home_sect_six_item_2 {
  grid-area: bigMain;
  background-color: rgb(204, 205, 205);
  padding-top: 50px;
}

.home_sect_six_item_2 .header_text {
  font-size: 60px !important;
  font-weight: 600;
}

.home_sect_six_item_2 .header_text,
.home_sect_six_item_4 .header_text {
  text-align: start;
  width: 50%;
}

.home_sect_six_item_2 .text_2 {
  font-size: 24px;
  width: 70%;
  margin-top: 20px;
}

.home_sect_six_item_2 .arrow_btn,
.home_sect_sev .arrow_btn {
  background-color: rgba(0, 0, 0, 0.1);
}

.home_sect_six_item_3 {
  grid-area: main;
  background: linear-gradient(184.5deg, #26ddf9 2.88%, #00b0cb 76.95%);
  color: white;
}

.home_sect_six_item_3 .arrow_btn,
.home_sect_six_item_4 .arrow_btn,
.home_sect_six_item_5 .arrow_btn {
  background-color: rgba(255, 255, 255, 0.6);
}

.home_sect_six_item_4 {
  grid-area: footer;
  background: linear-gradient(114.57deg, #fae9bc 4.52%, #dabe76 96.38%), #bebaa2;
}

.home_sect_six_item_4 .header_text {
  width: 80%;
}

.home_sect_six_item_5 {
  grid-area: footer1;
  background: linear-gradient(rgb(245, 158, 0) 0%, #fd6d6d 100%);
  color: white;
}

.home_sect > .text_text {
  text-decoration: underline;
  text-align: center;
  margin: 30px auto;
}

.home_sect_sev {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 90%;
  margin: 30px auto;
  padding-top: 30px;
}

.home_sect_sev .referral_item_two {
  margin-top: 50px;
}

.home_sect_sev .text_2 {
  font-family: "Poppins", sans-serif;
}

.home_sect_sev img {
  height: 450px;
}

.home_sect_sev > div {
  background-color: white;
  padding: 30px;
  padding-top: 0;
  border-radius: 10px;
  width: 33.3%;
}

.home_sect_eight {
  width: 90%;
  margin: 40px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 100px;
  margin-bottom: 200px;
}

.home_sect_eight img {
  z-index: 2;
}

.portfolio {
  width: 63% !important;
  margin-right: 15px;
}

.frame {
  position: absolute;
  inset: 0;
  width: 70%;
  margin: auto;
  z-index: 1;
}

.home_sect_nine {
  background-image: url(../../assets/laptopDesktopBg__1_.avif);
  background-color: rgba(0, 0, 0, 0.8);
  margin-bottom: 150px;
}

.home_sect_nine .referral_item_one {
  margin-top: 140px;
}

.home_direct_btn_container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 80px;
}

.home_direct_btn_container .btn {
  width: 250px;
}

.mobile_referral_sect {
  display: none;
}

@media (max-width: 600px) {
  .home_hero_sect {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "header"
      "main"
      "footer";
    padding: 30px 0;
  }

  .home_sect_three,
  .home_sect_four {
    flex-direction: column;
    height: auto;
  }

  .home_sect_six,
  .home_sect_sev {
    display: flex;
    flex-direction: column !important;
  }

  .home_hero_sect_one {
    grid-area: header;
    margin-top: 10px;
    padding: 0 20px;
    align-items: flex-start;
  }

  .home_hero_sect_one .btn {
    background-color: var(--other-color);
    color: white;
  }

  .home_hero_sect_one .header_text,
  .home_hero_sect_one .text_text {
    text-align: start;
    width: 100%;
  }

  .home_hero_trading_sect {
    grid-area: main;
    margin-top: 0px;
  }

  .home_hero_tablet_sect {
    grid-area: footer;
    margin-top: -150px;
  }

  .home_hero_sect_one,
  .home_hero_trading_sect,
  .home_hero_tablet_sect,
  .home_sect_four > div,
  .home_sect_sev > div,
  .referral_item_one,
  .unlock_home_sect .referral_item_one {
    width: 100%;
  }

  .home_referral_sect {
    padding: 40px 20px;
    background-image: url(../../assets/Referral_Club_Mobile.avif);
  }

  .home_referral_sect .home_header_text {
    width: 85%;
  }

  .home_header_text {
    font-size: 28px;
    line-height: 1.1;
  }

  .arrow_btn {
    height: 40px;
    width: 40px;
  }

  .home_hero_sect_one {
    position: static;
  }

  .header_text {
    font-size: 48px;
  }

  .home_sect > .text_2 {
    width: 100%;
    text-align: start;
    padding: 0 20px;
  }

  .home_sect_three {
    display: none;
  }

  .mobile_referral_sect {
    display: flex;
    background-image: none;
    background: linear-gradient(
      rgba(0, 0, 0, 0) 52.46%,
      rgba(0, 0, 0, 0.06) 73.81%,
      rgba(0, 0, 0, 0.44) 95.17%
    );
    height: auto;
    overflow: hidden;
    position: relative;
  }

  .mobile_referral_sect .referral_item_two {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    padding: 0 20px;
    z-index: 2;
  }

  .video {
    margin: auto;
    margin-top: 80px;
    margin-bottom: -120px;
  }

  .mobile_referral_sect .header_text {
    color: var(--other-color);
  }

  .home_sect_four > div {
    min-height: 550px;
  }

  .home_sect_five {
    background-image: url(../../assets/derivativesBgMobile__1_.avif);
  }

  .home_sect_five .referral_item_one {
    width: 100%;
  }

  .bold_home_text {
    text-align: start;
    margin-top: 0;
    padding: 0 20px;
  }

  .home_sect_six_item_2 .text_2,
  .home_sect_six_item_4 .header_text,
  .home_sect_eight {
    width: 100%;
  }

  .home_sect_eight {
    margin: 100px 0;
  }

  .frame {
    width: 100%;
  }

  .portfolio {
    width: 93% !important;
    margin-right: 10px;
  }

  .home_sect > .text_text {
    text-align: start;
  }

  .home_sect_nine {
    background-image: url(../../assets/Institutions_Mobile.avif);
  }

  .home_sect_nine .referral_item_one .home_header_text {
    font-size: 45px;
    line-height: 48px;
    width: 100%;
  }

  .home_sect_nine .referral_item_one {
    margin-top: 0;
  }

  .home_direct_btn_container {
    justify-content: flex-start;
    padding-left: 20px;
  }

  .text_2 {
    font-size: 24px;
  }
}
