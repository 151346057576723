.privacy_container {
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    padding: 50px;
    gap: 50px;
}

.privacy_container_item_one {
    padding: 30px;
    width: 35%;
    border-radius: 10px;
    max-height: 450px;
}

.privacy_container_item_two {
    width: 65%;
    height: 100vh;
    overflow-y: scroll;
}

.privacy_container_item_two .home_header_text {
    border-bottom: 2px solid rgb(230, 230, 230);
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.privacy_container .text_text {
    width: 100%;
    text-align: start;
    margin: 0;
}

.privacy_container_item_one .text_text {
    font-size: 16px;
    font-weight: 500;
    margin: 20px 0;
}

.privacy_container_item_two>.text_text {
    margin-bottom: 30px;
}

.privacy_container_item_two .text_text {
    font-size: 17px;
}

.privacy_items {
    margin-top: 50px;
}

.privacy_items h3 {
    margin-bottom: 20px;
}

.margin_top {
    margin-top: 30px !important;
}

.privacy_sections .home_header_text {
    font-size: 28px;
    border: none;
    margin: 0;
    padding: 0;
}

.privacy_container_item_two .text_text span {
    font-size: 20px;
    font-weight: 600;
}

@media(max-width:600px) {
    .privacy_container {
        flex-direction: column;
        padding: 50px 20px;
    }

    .privacy_container_item_two {
        height: auto;
        overflow: auto;
    }

    .privacy_container>div {
        width: 100%;
    }
}