header {
  background-color: var(--other-color);
  height: 100px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  inset: 0;
  z-index: 999;
}

.header_btn_sect button:nth-of-type(1) {
  background-color: transparent;
  color: white;
  width: 50px;
  font-size: 15px;
}

.header_navigation a {
  color: white;
  transition: color ease-in-out 300ms;
}

a:hover {
  color: #6acac2;
}

.header_btn_sect,
.header_navigation {
  display: flex;
  align-items: center;
  gap: 30px;
}

.header_logo_sect img,
footer img {
  width: 100px;
}

.menu_harm {
  display: none;
}

.mobile_logo {
  display: none;
}

.mobile_header_navigation {
  display: none;
}

@media (max-width: 600px) {
  .mobile_header_navigation {
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-color: black;
    position: fixed;
    inset: 0;
    padding: 30px;
    transition: all ease-in-out 300ms;
    transform: translateX(200%);
  }

  .show_nav {
    transform: translateX(0%);
  }

  .mobile_header_navigation_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobile_header_navigation a {
    color: white;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0px;
  }

  .mobile_header_navigation .btn {
    width: 100%;
    height: 55px;
    font-size: 16px;
  }

  .mobile_header_navigation > .mobile_nav_btn_con {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .mobile_header_navigation > .mobile_nav_btn_con .btn:nth-child(1) {
    background-color: transparent;
    color: white;
  }

  header {
    padding: 0 20px;
  }

  .mobile_logo {
    display: block;
    width: 50px !important;
    height: 50px;
  }

  .header_logo_sect img:nth-child(1) {
    display: none;
  }

  .menu_harm {
    display: block;
  }

  .header_navigation {
    display: none;
  }

  .header_btn_sect button:nth-child(1) {
    display: none;
  }

  .header_btn_sect button {
    height: 35px;
  }
}
