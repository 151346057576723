.terms_terms {
    padding: 50px 20px;
    padding-top: 130px;
}

.terms_terms .text_text {
    text-align: start;
    font-weight: 400;
    width: 100%;
}

@media(max-width:600px) {
    .terms_terms {
        padding-top: 80px;
    }
}