.btn {
    background-color: var(--secondary-color);
    color: var(--other-color);
    border: none;
    width: 100px;
    height: 45px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.33px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
}